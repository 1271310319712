<template>
<div>
  <div class="topwl">
    <div class="wz">
      <div class="wzt">
        单维策略
      </div>
      <div class="wzc">
       <p>单维策略”并不是一个通用词汇，特指蓝目训练器的一个特色功能,它是</p> 
       <p>我们在对"策略"理解的基础.上开发的单只股票交易操作测试功能。</p>
      </div>
    </div>
    <div class="tapl">
      <div class="tapi">
        <div class="tapimg"><img :src="require('@/assets/images/suanf.png')" alt="" /></div>
        <div class="tapw">
          算法及附加条件参数
          </div>
      </div>
      <div class="tapi">
        <div class="tapimg"><img :src="require('@/assets/images/mingx.png')" alt="" /></div>
        <div class="tapw">
          明细及交易记录展示
          </div>
      </div>
      <div class="tapi">
        <div class="tapimg"><img :src="require('@/assets/images/shouji.png')" alt="" /></div>
        <div class="tapw">
          收益率曲线及相关性能指标
          </div>
      </div>
    </div>
  </div>
  <div class="baskets">
    <div class="w-con">
      <div class="ws">
        <p>
          适用于交易者的自选股或者有限设定的品种标的，可以实现单只股票策略建立、
        </p>
        <p>回测、交易的一体化，极大的方便了操作者的操盘行为。</p>
      </div>
      <div class="wbtn">特色功能</div>
    </div>

    <div class="bg-con">
      <img :src="require('@/assets/images/danw1.jpg')" alt="" />
    </div>
  </div>
  <div class="basketf ohei">
    <!-- <div class="top-tit">篮子策略</div> -->
    <div class="f-con">
      <div class="f-w">
        <!-- <div class="blueline"></div> -->
        <div class="word">
          <p class="big-t">策略编辑可以简单，可以普及</p>
          <p class="lit-t">不再是计算机高端人才的自留地</p>
        </div>
      </div>
      <div class="f-img tright">
        <img :src="require('@/assets/images/danw2.png')" alt="" />
      </div>
    </div>
  </div>
  <div class="myline"></div>

  <div class="basketf ohei">
    <!-- <div class="top-tit">篮子策略</div> -->
    <div class="f-con">
      <div class="f-img tleft">
        <img :src="require('@/assets/images/danw.png')" alt="" />
      </div>
      <div class="f-w">
        <!-- <div class="blueline"></div> -->
        <div class="word">
          <p class="big-t">无需掌握计算机编程语言</p>
          <p class="lit-t">就能轻松完成策略编写</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

export default {

};
</script>

<style scoped lang="less">
.basketf {
  width: 100%;
  max-width: 1200px;
  min-width: 1000px;
  padding-top: 55px;
  margin: 0 auto;
  .top-tit {
    width: 100%;
    font-size: 25px;
    text-align: left;
  }
  .f-con {
    width: 100%;
    display: flex;
    padding-top: 85px;
    align-items: center;
    .f-img {
      width: 70%;
      img {
        max-width: 100%;
      }
    }
    .f-w {
      width: 30%;
      text-align: left;
      padding-bottom: 100px;
      .blueline {
        width: 60px;
        height: 6px;
        border-radius: 3px;
        background-color: #5ca1ff;
        margin-bottom: 15px;
      }
      .word {
        font-size: 18px;
        line-height: 40px;
      }
    }
  }
}
.tright {
  text-align: right;
}
.tleft {
  text-align: left;
}
.ohei {
  padding: 80px 0 150px;
}
.baskets {
  width: 100%;
  min-width: 1000px;
  position: relative;
  .bg-con {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .w-con {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    .ws {
      color: #ffffff;
      line-height: 36px;
      font-size: 20px;
      letter-spacing: 3px;
      padding-bottom: 50px;
    }
    .wbtn {
      cursor:pointer;
      padding: 4px 20px;
      display: flex;
      font-size: 22px;
      align-items: center;
      justify-content: center;
      color: #3873eb;
      background: #ffffff;
    }
  }
}
.big-t {
  font-size: 22px;
}
.lit-t {
  font-size: 16px;
  color: #999999;
  letter-spacing: 3px;
}
.myline {
  width: 100%;
  max-width: 1200px;
  min-width: 1000px;
  background: rgba(0, 0, 0, 0.09);
  height: 2px;
  margin: 0 auto;
}
.topwl{
  width: 100%;
  padding: 80px 0 90px;
  max-width: 1200px;
  min-width: 1000px;
  margin: 0 auto;
  .wz{
    .wzt{
      font-size: 26px;
    }
    .wzc{
      font-size: 14px;
      color: #999999;
      line-height: 22px;
      letter-spacing: 2px;
      padding: 30px 0;
    }
  }
  .tapl{
    width: 100%;
    padding: 0 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    .tapi{
      display: flex;
      flex-direction: column;
      align-items: center;
      .tapimg{
        width: 33%;
        width: 62px;
        height: 62px;
        display:flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0 40px;
      }
      .tapw{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;
        font-size: 18px;
        color: #ffffff;
        background: #3773ed;
      }
    }
  }
}
</style>